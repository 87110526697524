import React from 'react'
import { Container, Flex, Box } from 'theme-ui'
import Divider from '@solid-ui-components/Divider'
import FlexContent from '@solid-ui-components/FlexContent'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'

const FeaturesWithPhotoBlock02 = ({
  content: { text, images, buttons },
  reverse
}) => (
  <Container sx={{ position: `relative` }}>
    <Flex
      sx={{
        alignItems: ['center', 'center', 'center', 'start'],
        flexDirection: [
          reverse ? `column-reverse` : `column`,
          reverse ? `column-reverse` : `column`,
          reverse ? `column-reverse` : `column`,
          reverse ? `row-reverse` : `row`,
        ],
      }}
    >
      <Box
        sx ={{
          flexBasis: '1/2',
          pr: [null, null, null, 4],
          mb: 4,
        }}
      >
        <ContentImages content={{ images }} reverse={reverse} />
      </Box>
      <FlexContent reverse={reverse}>
        <Box sx={{ textAlign: ['center', 'center', 'center', 'left'] }}>
          <ContentText content={text} />
        </Box>
        {buttons && (
          <Box sx={{ textAlign: ['center', 'center', 'center', 'left'] }}>
            <Divider space={3} />
            <ContentButtons content={buttons} />
          </Box>
        )}
      </FlexContent>
    </Flex>
  </Container>
)

export default FeaturesWithPhotoBlock02
