import React from 'react'
import { graphql } from 'gatsby';
import { Container, Flex } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Services from '@solid-ui-blocks/Features/Block06'
import Testimonials from '@solid-ui-blocks/Testimonials/Block03'
import About from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import ContentButtons from '@solid-ui-components/ContentButtons'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'

const IndexPage = props => {
  const { allBlockContent, heroImage } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Home' />
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero heroImage={heroImage} content={content['hero']} />
      </Container>
      <Divider space='5' />
      <Services content={content['services']} />
      <Divider space='5' />
      <Divider space='5' />
      <Testimonials content={content['testimonials']} />
      <Divider space='5' />
      <Divider space='5' />
      <About content={content['about']} />
      <Divider space='5' />
      <Container>
        <Flex sx={{ justifyContent: 'center' }}>
          <ContentButtons content={content['call-to-action']['buttons']} />
        </Flex>
      </Container>
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query indexPageBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/index", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
    heroImage: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
export default IndexPage
