import React from 'react'
import { Container, Flex, Box, css } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentButtons from '@solid-ui-components/ContentButtons'
import BackgroundImage from 'gatsby-background-image';
import './styles.css';

const styles = {
  minHeight: '60vh',
}


const HeroBlock01 = ({
  content: { text = [], buttons },
  heroImage
}) => {
  const imageData = heroImage.childImageSharp.fluid
  return (
    <BackgroundImage fluid={imageData} style={{ minHeight: styles.minHeight }} className="home-page-hero">
      <Container sx={{ minHeight: styles.minHeight }}>
        <Flex
          sx={{
            minHeight: styles.minHeight,
          }}
        >
          <Box
            sx={{
              margin: '4rem 2rem',
              flexBasis: '450px',
              textAlign: [`center`, null, null, `left`]
            }}
          >
            <Reveal effect='fadeInDown'>
              <ContentText content={text} />
            </Reveal>
            {buttons && (
              <Reveal
                effect='fadeInRight'
                delay={1}
                css={css({ mb: [4, null, null, 0] })}
              >
                {buttons && (
                  <>
                    <Divider space={3} />
                    <ContentButtons content={buttons} />
                  </>
                )}
              </Reveal>
            )}
          </Box>
        </Flex>
      </Container>
    </BackgroundImage>
  )
};

export default HeroBlock01
