import React from 'react'
import { Container, Flex, Box, css } from 'theme-ui'
import { ImQuotesRight } from 'react-icons/im'
import Reveal from '@solid-ui-components/Reveal'
import Tabs from '@solid-ui-components/Tabs'
import ContentText from '@solid-ui-components/ContentText'
import ContentContainer from '@solid-ui-components/ContentContainer'

const TestimonialsBlock03 = ({
  content: {collection },
}) => (
  <Container>
    <Flex
      sx={{
        alignItems: `center`,
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          flexBasis: ['1', null, '3/4'],
        }}
      >
        <Tabs variant='dots' position='bottom' space={3} autoplay autoplayInterval="6000">
          {collection?.map(({ container, text }, index) => (
            <ContentContainer
              content={container}
              variant='cards.paper'
              key={`item-${index}`}
              sx={{ position: `relative` }}
            >
              <Flex
                sx={{
                  alignItems: `center`,
                  position: `relative`,
                  flexWrap: `wrap`,
                  zIndex: 1
                }}
              >
                <Box sx={{ flex: [`100%`, 1], ml: [0, 4] }}>
                  <Reveal effect='fadeInUp'>
                    <ContentText content={text} />
                    {Array.from({ length: 3 }, (_, i) => (
                      <Reveal
                        key={`item-${i}`}
                        effect='fadeInRotate'
                        delay={0.2 * (i + 1)}
                        css={css({ display: `inline-block`, mt: 3 })}
                      >
                      </Reveal>
                    ))}
                  </Reveal>
                </Box>
              </Flex>
              <ImQuotesRight
                css={css({
                  size: `30%`,
                  color: `betaLighter`,
                  position: `absolute`,
                  transform: `translate(0, -20%)`,
                  bottom: 0,
                  right: 0
                })}
              />
            </ContentContainer>
          ))}
        </Tabs>
      </Box>
    </Flex>
  </Container>
)

export default TestimonialsBlock03
